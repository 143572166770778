import * as React from 'react';
import { Autosuggestions, CategoryHierarchy } from '@msdyn365-commerce/commerce-entities';
import { IHeaderSectionData } from './header-section.data';
import { IHeaderSectionProps } from './header-section.props.autogenerated';
import getSearchSuggestionsAction, { AutoSuggestInput, SuggestionType } from './actions/get-auto-suggest-suggestions';
import { IActionContext } from '@msdyn365-commerce/core';
import { Cart } from '@msdyn365-commerce/retail-proxy';

export interface IHeaderSectionViewProps extends IHeaderSectionProps<IHeaderSectionData> {
    categories: CategoryHierarchy[];
    cart: Cart | undefined;
    inputChange: (query: string, ctx: IActionContext) => {};
    suggestions: Autosuggestions | undefined;
}

export interface ISearchState {
    suggestions: Autosuggestions | undefined;
}
class HeaderSection extends React.PureComponent<IHeaderSectionProps<IHeaderSectionData>, ISearchState> {
    public constructor(props: IHeaderSectionProps<IHeaderSectionData>) {
        super(props);
        this.state = {
            suggestions: {}
        };
        this.inputChange = this.inputChange.bind(this);
    }
    public render(): JSX.Element | null {
        const {
            data: { categories, cart }
        } = this.props;
        const { suggestions } = this.state;
        const MLHeaderViewProps = {
            ...this.props,
            inputChange: this.inputChange,
            categories: categories.result,
            suggestions: suggestions,
            cart: typeof cart.result !== 'undefined' ? cart.result : undefined
        };
        return this.props.renderView(MLHeaderViewProps);
    }

    private async inputChange(query: string, ctx: IActionContext) {
        if (query.length > 0) {
            const searchSuggestions = await getSearchSuggestionsAction(
                new AutoSuggestInput(query, 50, SuggestionType.Product, '', ''),
                ctx
            );
            this.setState({ suggestions: searchSuggestions });
        }
    }
}

export default HeaderSection;
